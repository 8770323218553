<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="배송보드">
        <delivery-board></delivery-board>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          검색엔진
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>
        <order-e-s v-if="loaded[1]"></order-e-s>
      </b-tab>
<!--      <b-tab title="재무">
        <order-finance v-if="loaded[2]"></order-finance>
      </b-tab>-->
    </b-tabs>
  </div>
</template>

<script>
import DeliveryBoard from './DeliveryBoard.vue'
import OrderES from './OrderES.vue'
import OrderFinance from './OrderFinance.vue'

export default {
  name: 'Order',
  title: '주문 관리',
  components: {DeliveryBoard, OrderES, OrderFinance},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
